import React from "react";
import config from "../config";
import MenuBottom from "../components/MenuBottom";
import Allservice from "../components/AllService";

function Product(props) {
  console.log(props.productData.id);
  return (
    <>
      <div className="offcanvas offcanvas-end" id="canvasProduct">
        <div className="offcanvas-header">
          <i
            className="bi bi-chevron-left fs-2 text-muted"
            data-bs-dismiss="offcanvas"
          ></i>
          <div
            className="cartIcon"
            data-bs-toggle="offcanvas"
            data-bs-target="#canvasCart"
          >
            <i className="bi bi-basket"></i>
            <span className="badge bg-danger count">4</span>
          </div>
        </div>
        {props.productData.id !== 0 ? (
          <>
            <div className="offcanvas-body px-0">
              <img
                src={config.img_path+'/products/'+props.productData.image}
                className="rounded px-3"
                alt="Cinque Terre"
                width="100%"
              />
              <div className="px-4 fs-5">
                {props.productData.name}
              </div>
            </div>
            <div className="offcanvas-footer mb-3">
              <div className="col-4 mx-auto mb-2">
                <div className="d-flex gap-2 justify-content-between">
                  <div className="shadow-1 btn-Pcs">
                    <i className="bi bi-dash-lg mx-auto"></i>
                  </div>
                  <div className="number-Pcs fs-4">
                    <span className="mx-auto">1</span>
                  </div>
                  <div className="shadow-1 btn-Pcs">
                    <i className="bi bi-plus-lg mx-auto"></i>
                  </div>
                </div>
              </div>

              <div className="d-grid p-3">
                <button type="button" className="btn btn-default btn-block p-2">
                  ใส่ตะกร้า {props.productData.price} บาท
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <MenuBottom />
      <Allservice />
    </>
  );
}

export default Product;
