import { Link } from "react-router-dom";
import config from "../config";
import Swal from "sweetalert2";
import axios from "axios";
import { useState } from "react";
import moment from "moment/min/moment-with-locales";

function Cart() {
  return (
    <div className="offcanvas offcanvas-end" id="canvasCart">
      <div className="bgTest" style={{ height: "2rem" }}></div>
      <div className="offcanvas-header">
        <i
          className="bi bi-chevron-left fs-2 text-muted"
          data-bs-dismiss="offcanvas"
        ></i>
        <h4 className="offcanvas-title pe-3 text-muted">ตะกร้าของฉัน</h4>
        <i></i>
      </div>
      <div className="offcanvas-body">
        <div className="card cart-item shadow-1">
          <div className="card-body p-2">
            <div className="d-flex">
              <div className="p-2 w-25">
                <img
                  src="https://cdn.imweb.me/thumbnail/20220402/625faf9260288.jpg"
                  className="rounded"
                  alt="Cinque Terre"
                  width="100%"
                />
              </div>
              <div className="p-2 flex-grow-1">
                <div className="d-flex justify-content-between mb-2">
                  <div className="productName">
                    Lays/เลย์ ร็อค มันฝรั่งทอดกรอบ รสหมึกย่างฮอตชิลลี่ แผ่นหยัก
                  </div>
                  <div className="delete">
                    <i className="bi bi-trash3"></i>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="text-default fs-4">37.00</span> บาท
                  </div>
                  <div className="d-flex gap-2 justify-content-between">
                    <div className="shadow-1 btn-Pcs">
                      <i className="bi bi-dash-lg mx-auto"></i>
                    </div>
                    <div className="number-Pcs fs-4">
                      <span className="mx-auto">100</span>
                    </div>
                    <div className="shadow-1 btn-Pcs">
                      <i className="bi bi-plus-lg mx-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card cart-item shadow-1">
          <div className="card-body p-2">
            <div className="d-flex">
              <div className="p-2 w-25">
                <img
                  src="https://cdn.imweb.me/thumbnail/20220402/625faf9260288.jpg"
                  className="rounded"
                  alt="Cinque Terre"
                  width="100%"
                />
              </div>
              <div className="p-2 flex-grow-1">
                <div className="d-flex justify-content-between mb-2">
                  <div className="productName">
                    Lays/เลย์ ร็อค มันฝรั่งทอดกรอบ รสหมึกย่างฮอตชิลลี่ แผ่นหยัก
                  </div>
                  <div className="delete">
                    <i className="bi bi-trash3"></i>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="text-default fs-4">37.00</span> บาท
                  </div>
                  <div className="d-flex gap-2 justify-content-between">
                    <div className="shadow-1 btn-Pcs">
                      <i className="bi bi-dash-lg mx-auto"></i>
                    </div>
                    <div className="number-Pcs fs-4">
                      <span className="mx-auto">100</span>
                    </div>
                    <div className="shadow-1 btn-Pcs">
                      <i className="bi bi-plus-lg mx-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer cont-total-price">
        <div className="d-flex justify-content-between mb-2">
          <div className="my-auto h2 fw-bold text-muted">ราคารวม</div>
          <div className="fw-bold">
            <h1 className="price">200.00</h1>บาท
          </div>
        </div>
        <div className="d-grid">
          <button type="button" className="btn btn-lg btn-default btn-block">
            ถัดไป
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cart;
