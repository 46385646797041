import axios from "axios";
import { useEffect, useState, useRef } from "react";
import config from "../config";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Cart from "../components/Cart";
import Allservice from "../components/AllService";
import { Link } from "react-router-dom";
import MenuBottom from "../components/MenuBottom";

function Home() {
  const [catg, setCatg] = useState([]);

  const fetchData = async () => {
    let payload = {
      origin: config.origin,
    };
    await axios
      .post(config.api_pos + "/Product/getCatg", payload, config.headers())
      .then((res) => {
        setCatg(res.data.results);
      });
  };

  const [offset, setOffset] = useState(0);
  const [searchs, setSearchs] = useState("");

  const handlePageHome = () => {
    alert("Home");
  };
  const handlePageWishlist = () => {
    alert("Wishlist");
  };
  const handlePageSpecial = () => {
    alert("Special");
  };
  const handlePageMyList = () => {
    alert("รายการของฉัน");
  };
  const handleMenuScan = () => {
    alert("Scan me");
  };

  const handleClickProductSearch = () => {
    setSearchs((prev) => "");
    document.getElementById("inputSearch").focus();
  };

  const autoSearchProduct = async (keyword) => {
    if (keyword.length > 3) {
      console.log(keyword);
    }
  };
  const appLogo = useRef(null);
  const [appHight, setAppHight] = useState(0);
  setTimeout(() => {
    setAppHight(appLogo.current.offsetWidth);
  }, 100);

  useEffect(() => {
    // fetchData()
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return (e) => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        {/* navbar */}
        <div
          className="searchFixed bgTest"
          // style={{
          //   background: `rgba(255,255,255,${offset < 100 ? offset / 100 : 1})`,
          // }}
        >
          <div className="d-flex gap-3">
            <div
              className={`p-2 flex-grow-1 rounded-4 borderSearchFixed ${
                offset < 100 ? "bg-white" : "bg-light"
              }`}
            >
              <div className="d-flex">
                <i className="bi bi-search mx-1"></i>
                <div
                  onClick={handleClickProductSearch}
                  className="flex-grow-1 ms-2"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#canvasSearch"
                >
                  ค้นหาสินค้า...
                </div>
                <i
                  onClick={handleMenuScan}
                  className="bi bi-upc-scan ps-2 me-1 border-start"
                ></i>
              </div>
            </div>

            <div
              className="contCart rounded-circle bg-white text-center borderSearchFixed"
              style={{ width: "35px", height: "35px" }}
              data-bs-toggle="offcanvas"
              data-bs-target="#canvasCart"
            >
              <i className={`bi bi-bag-check fs-5 my-auto`}></i>
              <span className="badge bg-danger">4</span>
            </div>

            <img
              src="https://www.w3schools.com/bootstrap5/cinqueterre.jpg"
              className="rounded-circle border border-light border-3 borderSearchFixed"
              alt="Cinque Terre"
              width="35"
              height="35"
              data-bs-toggle="offcanvas"
              data-bs-target="#canvasCartBlank"
            />
          </div>
        </div>

        {/* BannerHead */}
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner" style={{ height: "32vh" }}>
            <div
              className="carousel-item active"
              style={{
                height: "32vh",
                background:
                  "url(https://www.parentsone.com/wp-content/uploads/2020/05/5.5-shopping_cover_page.jpg) no-repeat center top",
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className="carousel-item"
              style={{
                height: "32vh",
                background:
                  "url(https://img.pikbest.com/origin/06/44/24/50WpIkbEsTeDR.jpg!w700wp) no-repeat center center",
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className="carousel-item"
              style={{
                height: "32vh",
                background:
                  "url(https://img.pikbest.com/origin/08/42/52/86hpIkbEsTSaG.jpg!bw700) no-repeat center center",
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        </div>

        {/* BarcodeScanner */}
        <section id="scanBarcode"></section>

        {/* delivery */}
        <section className="d-flex gap-3 justify-content-center">
          <div className="col-5 cont-delivery">
            <div className="delivery-body">
              <div className="d-flex gap-2 justify-content-center">
                <img
                  src="https://i.pinimg.com/736x/8c/a2/8b/8ca28be0d37b54c06b25422d098d78be.jpg"
                  alt="Mooii Delivery"
                />
                <div className="my-auto">
                  <div className="deliText">Mooii Delivery</div>
                  <small>สั่งเลย! ส่งฟรี</small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-5 cont-delivery">
            <div className="delivery-body">
              <div className="d-flex gap-2 justify-content-center">
                <img
                  src="https://blog.udemy.com/wp-content/uploads/2014/05/bigstock-Vector-Promotion-Concept-Fla-57726575.jpg"
                  alt="Promotion"
                />
                <div className="my-auto">
                  <div className="deliText">Good Promotion!</div>
                  <small>โปรดีมีทุกวัน</small>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* catagory */}
        <section id="catagory" className="my-3">
          <Carousel
            additionalTransfrom={0}
            arrows={0}
            autoPlay={false}
            autoPlaySpeed={1000}
            centerMode={false}
            className=""
            containerClass=""
            dotListClass=""
            draggable
            focusOnSelect={true}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 40,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <div className="cont-app">
              <div className="item-app">
              <Link to="productList?catgId=1"
                  className="col app-item"
                >
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://obs-ect.line-scdn.net/r/ect/ect/image_166253094356374355419c92084t10545c6f)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">ขนมขบเคี้ยว</div>
                </Link>
                <Link to="productList?catgId=2"
                  className="col app-item"
                >
                  <div
                    id="appLogos"
                    className="app-logo"
                    ref={appLogo}
                    style={{
                      backgroundImage:
                        "url(https://www.filantepizzeria.com.au/wp-content/uploads/2020/05/soft-drink-filante.jpg)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">น้ำดื่ม & เครื่องดื่ม</div>
                </Link>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage: "url(images/sauce.png)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">ซอส & ผงปรุงรส</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://www.maeban.co.th/upload_images/image/features/Reviewsuki/1.jpg)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">น้ำจิ้มพร้อมทาน</div>
                </div>
              </div>
              <div className="item-app">
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://f.btwcdn.com/store-45841/product/a93200c3-833a-171e-5b7d-618116e89e1e.jpg)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">ข้าวสาร</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://down-th.img.susercontent.com/file/2e0c81b5bd982d7840f8f5136940b743)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">บะหมี่กึ่งสําเร็จรูป</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage: "url(images/water-gas.png)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">น้ำ & แก๊ส สำหรับแลก</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://lh3.googleusercontent.com/proxy/ew3O42CnBIx-8qka3yFdjeytDhHkbX4KNZHoXVf5DpOez5f1oN61O2QcWOrtW9tMNyQFNiN1qdx5FC35xtKb8pcY6B_d-ONXvMRnbCOEf7FKxTEpVOkTZvywLOqdGngThUWk76kJOgkJNaxo8_U)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">เครื่องดื่มแอลกอฮอล์</div>
                </div>
              </div>
            </div>
            <div className="cont-app">
              <div className="item-app">
                <div className="col app-item ps-1">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://sukanyaonline.ca/cdn/shop/files/3D056173-AD37-40A1-983F-B14C63153191.jpg?v=1697396998)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">อาหารพร้อมปรุง</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://s3gw.inet.co.th:8082/smegp-image-4/smegp_images/53/SMEGP-2022-01-23-16-23-31-PRODUCT-001.jpg)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">แชมพู, ครีมอาบน้ำ</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://image.makewebeasy.net/makeweb/m_1920x0/73TxBoW2z/DefaultData/Homepage_GoodAgeCare_Design_page3_20.png?v=202405291424)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">ยาสีฝัน น้ำยาบ้วนปาก</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://down-th.img.susercontent.com/file/th-11134207-7r98q-lp2d2rfqjgq11b)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">โลชั่น ครีมกันแดด</div>
                </div>
              </div>
              <div className="item-app">
                <div className="col app-item ps-1">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://inwfile.com/s-do/9tlwv1.jpg)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">อุปกรณ์ซ่อมประปา</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://moivaonhatoi.com/sites/uploads/2021/03/den-led.jpg)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">อุปกรณ์ซ่อมไฟฟ้า</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://focusshield.com/wp-content/uploads/2023/02/additional-item-new-phone-9.jpg)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">อุปกรณ์เสริมมือถือ</div>
                </div>
                <div className="col app-item">
                  <div
                    className="app-logo"
                    style={{
                      backgroundImage:
                        "url(https://f.ptcdn.info/740/055/000/p2sllb4trbCBu3sZQsl-o.jpg)",
                      height: appHight,
                    }}
                  ></div>
                  <div className="app-name">ยาสามัญประจำบ้าน</div>
                </div>
              </div>
            </div>
          </Carousel>
        </section>

        {/* spacial */}
        <section id="spacial" className="px-3 my-3">
          <div className="d-flex justify-content-between">
            <div className="fw-bold text-default">โปรโมชั่น สิทธิพิเศษ</div>
            <small>ดูทั้งหมด {">>"}</small>
          </div>

          <Carousel
            additionalTransfrom={0}
            arrows={0}
            autoPlay={false}
            autoPlaySpeed={1000}
            centerMode={false}
            className=""
            containerClass="cont-banner"
            dotListClass=""
            draggable
            focusOnSelect={true}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <div
              className="banner-item"
              style={{
                backgroundImage:
                  "url(https://crosswalkagency.com/wp-content/uploads/2021/07/1-%E0%B8%9B%E0%B8%81.png)",
              }}
            ></div>
            <div
              className="banner-item"
              style={{
                backgroundImage:
                  "url(https://storage.googleapis.com/fastwork-static/035250b3-bd4a-4cbf-83bb-c8d9fc700bfe.jpg)",
              }}
            ></div>
          </Carousel>
        </section>

        {/* bottomMenu */}
        <MenuBottom />

        {/* Search */}
        <div className="offcanvas offcanvas-start" id="canvasSearch">
          <div className="offcanvas-header">
            <i></i>
            <h3 className="offcanvas-title ps-3 text-muted">ค้นหาสินค้า</h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
            ></button>
          </div>
          <div className="offcanvas-body px-0">
            <div id="input-search" className="mx-3">
              <input
                value={searchs}
                onChange={(e) => {
                  setSearchs((prev) => e.target.value);
                  autoSearchProduct(e.target.value);
                }}
                id="inputSearch"
                type="search"
                className="form-control mb-3 small border-3 border-default rounded-4"
                placeholder="ป้อนคำค้นหา.."
              />
            </div>

            <div id="const-search">
              <p className="mx-3 mb-2">พบสินค้า 2 รายการ</p>
              <div className="row mx-2 const-search">
                <div className="search-item col-6">
                  <div className="search-body">
                    <img
                      src="https://cdn.imweb.me/thumbnail/20220402/625faf9260288.jpg"
                      className="rounded"
                      alt="Cinque Terre"
                      width="100%"
                    />
                    <div className="name">
                      Lays/เลย์ ร็อค มันฝรั่งทอดกรอบ รสหมึกย่างฮอตชิลลี่
                      แผ่นหยัก
                    </div>
                    <div className="price">20</div>บาท
                  </div>
                </div>
                <div className="search-item col-6">
                  <div className="search-body">
                    <img
                      src="https://st.bigc-cs.com/cdn-cgi/image/format=webp,quality=90/public/media/catalog/product/72/88/8858705607572/8858705607572_1-20221214112904-.jpg"
                      className="rounded"
                      alt="Cinque Terre"
                      width="100%"
                    />
                    <div className="name">
                      Lays/เลย์ ร็อค มันฝรั่งทอดกรอบ รสหมึกย่างฮอตชิลลี่
                      แผ่นหยัก
                    </div>
                    <div className="price">20</div>บาท
                  </div>
                </div>
                <div className="search-item col-6">
                  <div className="search-body">
                    <img
                      src="https://pim-cdn0.ofm.co.th/products/original/0000117.jpg?v=20240409&x-image-process=image/format,webp"
                      className="rounded"
                      alt="Cinque Terre"
                      width="100%"
                    />
                    <div className="name">
                      Lays/เลย์ ร็อค มันฝรั่งทอดกรอบ รสหมึกย่างฮอตชิลลี่
                      แผ่นหยัก
                    </div>
                    <div className="price">20</div>บาท
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Cart Blank */}
        <div className="offcanvas offcanvas-end" id="canvasCartBlank">
          <div className="bgTest" style={{ height: "2rem" }}></div>
          <div className="offcanvas-header">
            <i
              className="bi bi-chevron-left fs-2 text-muted"
              data-bs-dismiss="offcanvas"
            ></i>
            <h4 className="offcanvas-title pe-3 text-muted">ตะกร้าของฉัน</h4>
            <i></i>
          </div>
          <div className="offcanvas-body position-relative cart-blank-body">
            <div className="text-center fs-6 text-muted">
              <h1
                className="bi bi-basket text-default"
                style={{
                  fontSize: "5em",
                  textShadow: "1px 3px 9px rgba(103,89,89,0.3  )",
                }}
              ></h1>
              <span className="text-secondary" style={{ fontWeight: "600" }}>
                ไม่พบสินค้าในตะกร้า
              </span>
            </div>
          </div>

          <div
            className="offcanvas-footer text-center"
            style={{ marginBottom: "13vh" }}
          >
            <button
              type="button"
              className="btn btn-lg btn-default fs-6"
              style={{ fontWeight: "500", padding: ".8rem 2.5rem" }}
            >
              เลือกสินค้า
            </button>
          </div>
        </div>

        <Cart />
        <Allservice />
      </div>
    </>
  );
}

export default Home;
