import React from 'react'
import { Link } from 'react-router-dom'

function MenuBottom() {
  return (
    <div className="bottomMenu">
    <div className="d-flex justify-content-between">
      <Link to="/" className="btMenu">
        <i className="bi bi-house-check clearfix"></i>
        <small>หน้าหลัก</small>
      </Link>
      <Link to="/wishlist"  className="btMenu">
        <i className="bi bi-heart clearfix"></i>
        <small>ที่ฉันชอบ</small>
      </Link>
      <Link to="/special" className="btMenu">
        <i className="bi bi-box2-heart clearfix"></i>
        <small>สิทธิพิเศษ</small>
      </Link>
      <Link to="/myList" className="btMenu">
        <i className="bi bi-file-earmark-text clearfix"></i>
        <small>รายการ</small>
      </Link>
      <div
        className="btMenu"
        data-bs-toggle="offcanvas"
        data-bs-target="#canvasAllService"
      >
        <i className="bi bi-grid-fill clearfix"></i>
        <small>ออเซอร์วิส</small>
      </div>
    </div>
      {/* <div className="btMenu">
        <div className="btMenu-main d-flex flex-wrap align-content-center">
          <i
            className="bi bi-plus-lg mx-auto"
            data-bs-toggle="offcanvas"
            data-bs-target="#demo"
          ></i>
        </div>
      </div> */}
  </div>
  )
}



export default MenuBottom
