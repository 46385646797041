import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config";
import axios from "axios";
import Cart from "../components/Cart";
import Product from "./Product";

function ProductList() {
  const [catagoryData, setCatagoryData] = useState({
    catgName: "",
    products: [],
  });

  const fetchData = async (catgId) => {
    const payload = {
      origin: config.origin,
      catgId: catgId,
    };
    await axios
      .post(
        config.api_pos + "/Product/productOnCatg",
        payload,
        config.headers()
      )
      .then((res) => {
        if (res.data.message === "success") {
          setCatagoryData(res.data);
        }
      });
  };

  // const [currentProduct, setCurrentProduct] = useState({
  //   name : "",
  //   price:0,
  //   stock:0
  // })

  const [currentProduct, setCurrentProduct] = useState({
    id : 0
  })

  useEffect(() => {
    fetchData(window.location.href.split("=")[1]);
  }, []);

  return (
    <>
    <div className="card border-0">
      <div className="card-header bg-white border-0 p-0">
        <div className="bgTest" style={{ height: "2rem" }}></div>
        <div className="d-flex justify-content-between px-3">
          <Link to="/" className="bi bi-chevron-left fs-2 text-muted"></Link>
          <h5 className="offcanvas-title text-muted">
            {catagoryData.catgName}
          </h5>
          <div
            className="cartIcon"
            data-bs-toggle="offcanvas"
            data-bs-target="#canvasCart"
          >
            <i className="bi bi-basket"></i>
            <span className="badge bg-danger count">4</span>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <div id="const-prodList" className="mt-3">
          <div className="row mx-2 const-prodList">
            {catagoryData.products.length > 0
              ? catagoryData.products.map((item, index) => (
                  <div key={index} className="prodList-item col-6" 
                  onClick={e => setCurrentProduct(item)}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#canvasProduct">
                    <div className="prodList-body">
                      <img
                        src={config.img_path + "/products/" + item.image}
                        className="rounded"
                        alt="Cinque Terre"
                        width="100%"
                      />
                      <div className="name">{item.name}</div>
                      <div className="price">{item.price}</div>บาท
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
    <Cart />
    <Product productData={currentProduct} />
    </>
  );
}

export default ProductList;
