import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//Pages
import Home from './pages/Home';
import ProductList from './pages/ProductList';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },{
    path: "/productList",
    element: <ProductList />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} basename={""} />);
reportWebVitals();

