import { Link } from "react-router-dom";
import config from "../config";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useRef } from "react";

function Allservice() {
  const appServiceLogo = useRef(null);
  const [appServiceHight, setAppServiceHight] = useState(0);
  setTimeout(() => {
    setAppServiceHight(appServiceLogo.current.offsetWidth);
  }, 100);

  return (
    <div className="offcanvas offcanvas-bottom h-100" id="canvasAllService">
      <div className="offcanvas-header">
        <h1 className="offcanvas-title">All Service</h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
        ></button>
      </div>
      
      <div className="offcanvas-body">
        <div className="cont-app mx-0">
          <div className="item-app">
            <div className="col app-item ps-0 ms-0">
              <div
                className="app-logo"
                ref={appServiceLogo}
                style={{
                  backgroundImage:
                    "url(https://www.datocms-assets.com/88735/1687987549-vu_mycashtopup_web-676x561px.jpg)",
                  height: appServiceHight,
                }}
              ></div>
              <div className="app-name">เติมเงินมือถือ ทุกค่าย</div>
            </div>
            <div className="col app-item">
              <div
                className="app-logo"
                style={{
                  backgroundImage:
                    "url(https://play-lh.googleusercontent.com/E7jF7pWoOtNlTEObEsJWYl2ozv3pI4vsZuT1f_w46CQA-bSRq720eJ5vZJWJLfVJmgo)",
                  height: appServiceHight,
                }}
              ></div>
              <div className="app-name">โปรเสริมเน็ตมือถือ</div>
            </div>
            <div className="col app-item">
              <div
                className="app-logo"
                style={{
                  backgroundImage:
                    "url(https://nocnoc.com/service-installation/images/how-to/how-to-1.png?w=256&q=75)",
                  height: appServiceHight,
                }}
              ></div>
              <div className="app-name">บริการ สารพัดช่าง</div>
            </div>
            <div className="col app-item">
              <div
                className="app-logo"
                style={{
                  backgroundImage:
                    "url(https://assets.brandinside.asia/uploads/2021/06/2.jpg)",
                  height: appServiceHight,
                }}
              ></div>
              <div className="app-name">ส่งพัสดุทั่วไทย รวมขนส่ง</div>
            </div>
          </div>
          <div className="item-app">
            <div className="col app-item ps-0 ms-0">
              <div
                className="app-logo"
                style={{
                  backgroundImage:
                    "url(https://pum-shop.com/images/category/otp24hr/0PWLUPZDU3V3YEE90VNM_netflix.png)",
                  height: appServiceHight,
                }}
              ></div>
              <div className="app-name">แอพพรีเมี่ยม ราคาถูก</div>
            </div>
            <div className="col app-item">
              <div
                className="app-logo"
                style={{
                  backgroundImage:
                    "url(https://play-lh.googleusercontent.com/UD3M7vEIbLINyar4sV70Sf8k8jxzYVQKvwKDCeHF5IeVgVfLjB1aivaSV0WdJGyZetw)",
                  height: appServiceHight,
                }}
              ></div>
              <div className="app-name">บัตรเงินสด เติมเกม</div>
            </div>
            <div className="col app-item"></div>
            <div className="col app-item"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Allservice;
