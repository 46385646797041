const protocol = window.location.protocol + "//"; // https:
const hostname = window.location.hostname; // domain
const ports = () => {
  let portnumber = window.location.port;
  let port = "";
  if (portnumber.length !== 0) {
    port += `:${portnumber}`;
  }
  return port;
};

const config = {
  origin: protocol + hostname + ports(),
  api_uzip: "https://uzip.me/api",
  // api_path: "http://10.11.10.240:3001",
  api_pos: "https://api.allservice.in.th/controlX/pos",
  api_host: "https://api.allservice.in.th/controlX/erp",
  api_express: "https://api.allservice.in.th/controlX/express",
  img_path: "images",
  token_name: "erp_token",
  headers: () => {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  },
  headersImage: () => {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("erp_token"),
        "Content-Type": "multipart/form-data",
      },
    };
  },
  headersHost: () => {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  },
  headersEsc: () => {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  },
  table_perpage: 15,
  //Access
  access: {
    //Job
    jobList: localStorage.getItem("jobList"),
    jobSearch: localStorage.getItem("jobSearch"),
    //Sale
    customer: localStorage.getItem("customer"),
    qoutation: localStorage.getItem("qoutation"),
    //Inventory
    productList: localStorage.getItem("productList"),
    stockIn: localStorage.getItem("stockIn"),
    warrantyList: localStorage.getItem("warrantyList"),
    warrantySearch: localStorage.getItem("warrantySearch"),
    warrantySetting: localStorage.getItem("warrantySetting"),
    reportStock: localStorage.getItem("reportStock"),
    booking: localStorage.getItem("booking"),
    getCourier: localStorage.getItem("getCourier"),
    reportBooking: localStorage.getItem("reportBooking"),
    //HR
    employeeList: localStorage.getItem("employeeList"),
    employeeOT: localStorage.getItem("employeeOT"),
    employeeLeaveLate: localStorage.getItem("employeeLeaveLate"),
    employeeResign: localStorage.getItem("employeeResign"),
    equipment: localStorage.getItem("equipment"),
    equipmentPick: localStorage.getItem("equipmentPick"),
    equipmentBroken: localStorage.getItem("equipmentBroken"),
  },
  userData: {
    id: localStorage.getItem("userId"),
    name: localStorage.getItem("userName"),
    level: localStorage.getItem("level"),
    imageProfile: localStorage.getItem("imgProfile"),
  },
  memberData: {
    id: localStorage.getItem("memberId"),
    name: localStorage.getItem("memberName"),
    phone: localStorage.getItem("memberPhone"),
    address: localStorage.getItem("memberAddress"),
    memberAddressTH: localStorage.getItem("memberAddressTH"),
    idCard: localStorage.getItem("idCard"),
    maxUser: localStorage.getItem("maxUser"),
    expressId: localStorage.getItem("expressId"),
  },
  branchData: {
    id: localStorage.getItem("branchId"),
    pickupId: localStorage.getItem("pickupId"),
    name: localStorage.getItem("branchName"),
    phone: localStorage.getItem("branchPhone"),
    address: localStorage.getItem("branchAddress"),
  },
  getParams: new URLSearchParams(window.location.search), //config.getParams.get("action")
  qoutationKey: localStorage.getItem("qoutationKey"),
  warrantyKey: localStorage.getItem("warrantyKey"),
};

export default config;
